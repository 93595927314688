export const enhancer = (element) => {
  const label = document.querySelector(".field-label--images");

  element.addEventListener("change", (e) => {
    const filenames = e.target.files;

    label.textContent =
      filenames.length > 1
        ? `${filenames.length} files`
        : e.target.files[0].name;
  });
};

/* eslint-disable no-return-assign, no-use-before-define, no-underscore-dangle */

import {
  disableDocument,
  enableDocument,
  enableScroll,
  disableScroll,
} from "./util";

const MobileMenu = (menu) => {
  const toggleButton = document.querySelector(
    `#${menu.getAttribute("aria-controlledby")}`
  );

  const isMenuOpen = () => menu.getAttribute("aria-hidden") === "false";

  const isMenuRelated = (e) =>
    e.target === menu ||
    e.target === toggleButton ||
    toggleButton.contains(e.target) ||
    menu.contains(e.target);

  const createCloseHandlers = () => {
    const removeHandlers = () => {
      removeListener("click", clickHandler);
    };
    const clickHandler = (e) => {
      if (!isMenuRelated(e)) {
        e.preventDefault();
        close();
        removeHandlers();
      }
    };
    return { clickHandler };
  };

  const closeHandlers = createCloseHandlers();

  const addListener = (action, handler) =>
    document.addEventListener(action, handler);
  const removeListener = (action, handler) =>
    document.removeEventListener(action, handler);

  const handleEscapeKey = (e) => {
    if (e.key !== "Escape") return;

    toggle();
  };

  const close = () => {
    menu.setAttribute("aria-hidden", "true");
    toggleButton.setAttribute("aria-expanded", "false");
    enableDocument(menu);
    toggleButton.focus();
    toggleButton.blur();
  };

  const open = () => {
    menu.setAttribute("aria-hidden", "false");
    toggleButton.setAttribute("aria-expanded", "true");
    disableDocument(menu);
  };

  const toggle = () => {
    if (isMenuOpen()) {
      close();
      removeListener("click", closeHandlers.clickHandler);
      removeListener("keydown", handleEscapeKey);
      window.removeEventListener("resize", toggle);
      enableScroll();
    } else {
      open();
      addListener("click", closeHandlers.clickHandler);
      addListener("keydown", handleEscapeKey);
      window.addEventListener("resize", toggle);
      disableScroll();
    }
  };

  return {
    init() {
      if (!menu) {
        return;
      }

      menu.setAttribute("aria-hidden", "true");
      menu.setAttribute("aria-modal", "true");
      toggleButton.setAttribute("aria-expanded", "false");

      menu._methods = { toggle };
    },
  };
};

export const enhancer = (menu) => {
  const mobileMenu = MobileMenu(menu);
  mobileMenu.init();
};

export const handler = (toggle, e) => {
  e.preventDefault();
  const menu = document.querySelector(
    `#${toggle.getAttribute("aria-controls")}`
  );
  menu._methods.toggle();
};

/**
 * Toggle the question and answer.
 */
const toggle = (question, forceExpand = false) => {
  const shouldExpand =
    forceExpand || question.getAttribute("aria-expanded") !== "true";
  const answer = document.getElementById(
    question.getAttribute("aria-controls")
  );

  question.setAttribute("aria-expanded", shouldExpand);
  answer.setAttribute("aria-hidden", !shouldExpand);
};

/**
 * Handle question click and add current question to the URL for easy sharing.
 */
export const handler = (el, e) => {
  e.preventDefault();
  toggle(el);
  window.history.replaceState({}, "", el.hash);
  setTimeout(() => el.blur(), 0);
};

/**
 * Show question when opened with the hash in the URL.
 */
export const enhancer = (el) => {
  const { hash } = window.location;
  if (!hash) {
    return;
  }

  const question = el.querySelector(hash);

  if (!question) {
    return;
  }

  toggle(question, true);
  window.setTimeout(() => question.blur(), 1000);
};

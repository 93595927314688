const FETCH_PARAMS = {
  method: "post",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

const triggerEvent = (el, type) => {
  const e = document.createEvent("HTMLEvents");
  e.initEvent(type, false, true);
  el.dispatchEvent(e);
};

const maybeFetchAddress = (form) => (e) => {
  const apiEndpoint = form.getAttribute("data-postcode-api-endpoint");
  const zipcodeInput = form.querySelector('input[name="zipcode"]');
  const housenumberInput = form.querySelector('input[name="housenumber"]');
  const addressInput = form.querySelector('input[name="address"]');
  const cityInput = form.querySelector('input[name="city"]');

  if (zipcodeInput.value && housenumberInput.value) {
    const data = {
      zipcode: zipcodeInput.value,
      housenumber: housenumberInput.value,
    };
    fetch(apiEndpoint, { ...FETCH_PARAMS, body: JSON.stringify(data) }).then(
      (response) => {
        if (!response.ok) {
          return response.json().then((result) => {
            console.log(result, "fail");
          });
        }
        return response.json().then((result) => {
          // what if no address is found
          if (result.length === 0) {
            return;
          }
          const address = result[0];
          addressInput.value = address.street;
          cityInput.value = address.city.label;
          triggerEvent(addressInput, "blur");
          triggerEvent(cityInput, "blur");
          addressInput.disabled = false;
          cityInput.disabled = false;
        });
      }
    );
  }
};

export const enhancer = (form) => {
  const zipcodeInput = form.querySelector('input[name="zipcode"]');
  const housenumberInput = form.querySelector('input[name="housenumber"]');
  const addressInput = form.querySelector('input[name="address"]');
  const cityInput = form.querySelector('input[name="city"]');
  cityInput.disabled = true;
  addressInput.disabled = true;
  [zipcodeInput, housenumberInput].map((input) => {
    input.addEventListener("change", maybeFetchAddress(form));
  });
};

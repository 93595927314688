import Headroom from "headroom.js";

/**
 * Setup Headroom.js
 */
const initializeHeadroom = (header) => {
  const headroomSettings = {
    offset: header.offsetHeight,
    tolerance: {
      down: 0,
      up: 10,
    },
    classes: {
      initial: "is-loaded",
      pinned: "is-pinned",
      unpinned: "is-unpinned",
      bottom: "is-at-bottom",
      top: "is-at-top",
      notTop: "is-not-at-top",
      notBottom: "is-not-at-bottom",
    },
  };

  new Headroom(header, headroomSettings).init();
};

export const enhancer = (header) => {
  initializeHeadroom(header);
};

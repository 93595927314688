import { publish } from "../utils/observer";

export const enhancer = (element) => {
  const id = element.getAttribute("data-in-view-id");
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => publish(id, { inView: entry.isIntersecting }));
  });

  observer.observe(element);
};

const hideField = (input) => {
  input.parentElement.style.display = "none"; // should use aria-hidden
  input.should_be_required = input.required; // should use data attribute
  input.required = false;
};

const triggerEvent = (el, type) => {
  const e = document.createEvent("HTMLEvents");
  e.initEvent(type, false, true);
  el.dispatchEvent(e);
};

const composeVveName = (form) => {
  const address = form.querySelector('input[name="address"]');
  const housenumber = form.querySelector('input[name="housenumber"]');
  const vveName = `VVE ${address.value} ${housenumber.value}`;
  return vveName;
};

const showField = (input, form) => {
  input.parentElement.style.display = "block";
  if (input.name === "vve_name" && !input.value) {
    input.value = composeVveName(form);
    triggerEvent(input, "blur");
  }
  input.required = input.should_be_required;
};

const toggleField = (input, form) => (e) => {
  const valuesToCheck = input.getAttribute("data-conditional-value").split("|");
  return valuesToCheck.indexOf(e.target.value) >= 0
    ? showField(input, form)
    : hideField(input, form);
};

const showConditional = (form) => (input) => {
  const inputNameToCheck = input.getAttribute("data-conditional-field");
  const inputsToCheck = form.querySelectorAll(`[name='${inputNameToCheck}']`);
  hideField(input);
  [...inputsToCheck].map((inputToCheck) =>
    inputToCheck.addEventListener("change", toggleField(input, form))
  );
};

export const enhancer = (form) => {
  const conditionalFields = form.querySelectorAll("[data-conditional-field]");
  [...conditionalFields].map(showConditional(form));
};
